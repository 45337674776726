import React from "react";
import { useContext, useState } from "react";
import Ingredient from "./Ingredient";
import styles from "../../css/Ingredients.module.css";
import { CalculatorContext } from "../../context/CalculatorContext";
import {
  bowls,
  numbOfFlavors,
  typeOfProtein,
  protein,
  plantBasedProtein,
  twoFlavorPlantBasedProtein,
  twoFlavorProtein,
  proteinMini,
  proteinPlantBasedMini,
  extraTwoFillets,
  base,
  baseMini,
  rougeBowlsDressings,
  poutinDressingsAndToppings,
  toppingsRougeBowls,
  extraToppingsRougeBowls,
  toppingsMiniBowl,
  // sides,
  dessert,
} from "../../data";

const Ingredients = () => {
  const { recalculate, selected, startOver } = useContext(CalculatorContext);

  const [bowlClicked, setBowlClicked] = useState(null);
  const [proteinTypeClicked, setProteinTypeClicked] = useState(null);
  const [numbOfFlavorsClicked, setNumbOfFlavorsClicked] = useState(null);
  const [proteinClicked, setProteinClicked] = useState(null);
  const [miniProteinClicked, setMiniProteinClicked] = useState(null);
  const [baseClicked, setBaseClicked] = useState(null);
  const [baseMIniClicked, setBaseMIniClicked] = useState(null);
  const [dressingClicked, setDressingClicked] = useState(null);
  const [poutinDressingClicked, setPoutinDressingClicked] = useState(null);
  const [whichBowl, setWhichBowl] = useState(null);
  const [whichProteinType, setWhichProteinType] = useState(null);
  const [whichNumOfFalvors, setWhichNumOfFalvors] = useState(null);
  const [showSides, setShowSides] = useState(null);

  const handleAllReset = () => {
    setProteinTypeClicked(false);
    handleResetfromProteinType();
  };

  // Add missing states
  const handleResetfromProteinType = () => {
    setWhichNumOfFalvors(null);
    setNumbOfFlavorsClicked(false);
    setProteinClicked(false);
    setMiniProteinClicked(false);
    setBaseClicked(false);
    setBaseMIniClicked(false);
    setDressingClicked(false);
    setShowSides(false);
    setPoutinDressingClicked(null);
  };

  return (
    <div className={styles.ingredients}>
      <div className={styles.ing_container}>
        <div className={styles.whole_ing_card}>
          <div className={styles.h2Group}>
            <h2>Bols</h2>
            <h2
              className={styles.rougeBtn}
              onClick={() => {
                startOver();
                handleAllReset();
                setBowlClicked(false);
              }}
            >
              recommencer
            </h2>
          </div>

          <div className={styles.cards}>
            {bowls
              ? bowls.map((item) => {
                  return (
                    <Ingredient
                      key={item.name}
                      item={item}
                      type={"bowls"}
                      onClick={recalculate}
                      handleAllReset={handleAllReset}
                      handleBowlClick={() => {
                        setBowlClicked(true);
                      }}
                      setWhichBowl={setWhichBowl}
                      selected={
                        !!(
                          selected &&
                          selected["bowls"] &&
                          selected["bowls"][item.name]
                        )
                      }
                    />
                  );
                })
              : null}
            {/* ---- */}
          </div>
        </div>

        {bowlClicked ? (
          <div className={styles.whole_ing_card}>
            <h2>Type de protéine</h2>
            <div className={styles.cards}>
              {typeOfProtein
                ? typeOfProtein.map((item) => {
                    return (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"typeOfProtein"}
                        onClick={recalculate}
                        handleResetfromProteinType={handleResetfromProteinType}
                        handleProteinTypeClick={() => {
                          setProteinTypeClicked(true);
                        }}
                        setWhichProteinType={setWhichProteinType}
                        setWhichNumOfFalvors={setWhichNumOfFalvors}
                        selected={
                          !!(
                            selected &&
                            selected["typeOfProtein"] &&
                            selected["typeOfProtein"][item.name]
                          )
                        }
                      />
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}

        {proteinTypeClicked && whichBowl !== "Mini Bowl" ? (
          <div className={styles.whole_ing_card}>
            <h2>Nombre de saveurs</h2>
            <div className={styles.cards}>
              {numbOfFlavors
                ? numbOfFlavors.map((item) => {
                    return (
                      <Ingredient
                        key={item.name}
                        item={item}
                        button={true}
                        type={"numbOfFlavors"}
                        onClick={recalculate}
                        handleAllReset={handleAllReset}
                        handleNumOfFlavors={() => {
                          setNumbOfFlavorsClicked(true);
                        }}
                        setWhichNumOfFalvors={setWhichNumOfFalvors}
                        selected={
                          !!(
                            selected &&
                            selected["numbOfFlavors"] &&
                            selected["numbOfFlavors"][item.name]
                          )
                        }
                      />
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}

        {/* ----------------------- */}
        <>
          {proteinTypeClicked && whichBowl === "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Saveur</h2>
              <div className={styles.cards}>
                {proteinMini && whichProteinType === "Chicken Fillet"
                  ? proteinMini.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"proteinMini"}
                          onClick={recalculate}
                          handleMiniProteinClick={() => {
                            setMiniProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["proteinMini"] &&
                              selected["proteinMini"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : proteinPlantBasedMini &&
                    whichProteinType === "Plant-Based Fillet" &&
                    whichBowl === "Mini Bowl"
                  ? proteinPlantBasedMini.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"proteinPlantBasedMini"}
                          onClick={recalculate}
                          handleMiniProteinClick={() => {
                            setMiniProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["proteinPlantBasedMini"] &&
                              selected["proteinPlantBasedMini"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          ) : null}

          {/* Handling the entire fillets section with regular and plant based protein  */}
          {numbOfFlavorsClicked &&
          whichNumOfFalvors === "1 Flavour" &&
          whichBowl !== "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Saveur</h2>
              <div className={styles.cards}>
                {protein && whichProteinType === "Chicken Fillet"
                  ? protein.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"protein"}
                          onClick={recalculate}
                          handleProteinClick={() => {
                            setProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["protein"] &&
                              selected["protein"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : plantBasedProtein &&
                    whichProteinType === "Plant-Based Fillet" &&
                    whichBowl !== "Mini Bowl"
                  ? plantBasedProtein.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"plantBasedProtein"}
                          onClick={recalculate}
                          handleProteinClick={() => {
                            setProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["plantBasedProtein"] &&
                              selected["plantBasedProtein"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          ) : numbOfFlavorsClicked &&
            whichNumOfFalvors === "2 Flavours" &&
            whichBowl !== "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Choisissez 2 saveurs</h2>
              <div className={styles.cards}>
                {twoFlavorProtein && whichProteinType === "Chicken Fillet"
                  ? twoFlavorProtein.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"twoFlavorProtein"}
                          onClick={recalculate}
                          handleProteinClick={() => {
                            setProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["twoFlavorProtein"] &&
                              selected["twoFlavorProtein"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : twoFlavorPlantBasedProtein &&
                    whichProteinType === "Plant-Based Fillet"
                  ? twoFlavorPlantBasedProtein.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"twoFlavorPlantBasedProtein"}
                          onClick={recalculate}
                          handleProteinClick={() => {
                            setProteinClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["twoFlavorPlantBasedProtein"] &&
                              selected["twoFlavorPlantBasedProtein"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          ) : null}

          {/* This section is independant as long as 2 Flavors button was clciked  */}
          {/* =================== */}
          {proteinClicked && whichNumOfFalvors === "2 Flavours" ? (
            <div className={styles.whole_ing_card}>
              <h2>Extra 2 filets</h2>
              <div className={styles.cards}>
                {extraTwoFillets
                  ? extraTwoFillets.map((item, index) => {
                      if (index === 2) {
                        return null;
                      } else {
                        return (
                          <Ingredient
                            key={item.name}
                            item={item}
                            type={"extraTwoFillets"}
                            onClick={recalculate}
                            selected={
                              !!(
                                selected &&
                                selected["extraTwoFillets"] &&
                                selected["extraTwoFillets"][item.name]
                              )
                            }
                          />
                        );
                      }
                    })
                  : null}
              </div>
            </div>
          ) : (proteinClicked && whichNumOfFalvors === "1 Flavour") ||
            miniProteinClicked ? (
            <div className={styles.whole_ing_card}>
              <h2>Extra 1 filet</h2>
              <div className={styles.cards}>
                {extraTwoFillets
                  ? extraTwoFillets.map((item, index) => {
                      if (index === 1) {
                        return null;
                      } else {
                        return (
                          <Ingredient
                            key={item.name}
                            item={item}
                            type={"extraTwoFillets"}
                            onClick={recalculate}
                            selected={
                              !!(
                                selected &&
                                selected["extraTwoFillets"] &&
                                selected["extraTwoFillets"][item.name]
                              )
                            }
                          />
                        );
                      }
                    })
                  : null}
              </div>
            </div>
          ) : null}
          {/* =================== */}

          {miniProteinClicked && whichBowl === "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Bases (Choisissez 1)</h2>
              <div className={styles.cards}>
                {baseMini
                  ? baseMini.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"baseMini"}
                          onClick={recalculate}
                          handleBaseMiniClicked={() => {
                            setBaseMIniClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["baseMini"] &&
                              selected["baseMini"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : null}

          {baseMIniClicked && whichBowl === "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Sauces et vinaigrettes</h2>
              <div className={styles.cards}>
                {rougeBowlsDressings
                  ? rougeBowlsDressings.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          whichBowl={whichBowl}
                          type={"rougeBowlsDressings"}
                          onClick={recalculate}
                          handleDressingClicked={() => {
                            setDressingClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["rougeBowlsDressings"] &&
                              selected["rougeBowlsDressings"][
                                item.name
                              ]
                            )
                          }
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : null}

          {dressingClicked && whichBowl === "Mini Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Garnitures incluses (choisissez 3</h2>
              <div className={styles.cards}>
                {toppingsMiniBowl
                  ? toppingsMiniBowl.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"toppingsMiniBowl"}
                        handleShowSides={() => {
                          setShowSides(true);
                        }}
                        onClick={recalculate}
                        selected={
                          !!(
                            selected &&
                            selected["toppingsMiniBowl"] &&
                            selected["toppingsMiniBowl"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null}

          {/* -------------------------- */}

          {proteinClicked && whichBowl === "Rouge Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Bases (Choisissez 1 ou 2)</h2>
              <div className={styles.cards}>
                {base
                  ? base.map((item) => {
                      return (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"base"}
                          onClick={recalculate}
                          handleBaseClicked={() => {
                            setBaseClicked(true);
                          }}
                          selected={
                            !!(
                              selected &&
                              selected["base"] &&
                              selected["base"][item.name]
                            )
                          }
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : proteinClicked && whichBowl === "Rouge Poutine" ? (
            <div className={styles.whole_ing_card}>
              <h2>Sauces et garnitures</h2>
              <div className={styles.cards}>
                {poutinDressingsAndToppings
                  ? poutinDressingsAndToppings.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"poutinDressingsAndToppings"}
                        onClick={recalculate}
                        handlePoutineDressingClicked={() => {
                          setPoutinDressingClicked(true);
                        }}
                        selected={
                          !!(
                            selected &&
                            selected["poutinDressingsAndToppings"] &&
                            selected["poutinDressingsAndToppings"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null}

          {/*  ================= Dressings ====================   */}

          {baseClicked && whichBowl === "Rouge Bowl 2 Flavours" ? (
            <div className={styles.whole_ing_card}>
              <h2>Sauces et vinaigrettes</h2>
              <div className={styles.cards}>
                {rougeBowlsDressings
                  ? rougeBowlsDressings.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"rougeBowlsDressings"}
                        onClick={recalculate}
                        handleDressingClicked={() => {
                          setDressingClicked(true);
                        }}
                        selected={
                          !!(
                            selected &&
                            selected["rougeBowlsDressings"] &&
                            selected["rougeBowlsDressings"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null}

          {baseClicked && whichBowl === "Rouge Bowl" ? (
            <div className={styles.whole_ing_card}>
              <h2>Sauces et vinaigrettes</h2>
              <div className={styles.cards}>
                {rougeBowlsDressings
                  ? rougeBowlsDressings.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"rougeBowlsDressings"}
                        onClick={recalculate}
                        handleDressingClicked={() => {
                          setDressingClicked(true);
                        }}
                        selected={
                          !!(
                            selected &&
                            selected["rougeBowlsDressings"] &&
                            selected["rougeBowlsDressings"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null}

          {dressingClicked && whichBowl === "Rouge Bowl 2 Flavours" ? (
            <>
              <div className={styles.whole_ing_card}>
                <h2>Garnitures incluses (choisissez 7)</h2>
                <div className={styles.cards}>
                  {toppingsRougeBowls
                    ? toppingsRougeBowls.map((item) => (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"toppingsRougeBowls"}
                          handleShowSides={() => {
                            setShowSides(true);
                          }}
                          onClick={recalculate}
                          selected={
                            !!(
                              selected &&
                              selected["toppingsRougeBowls"] &&
                              selected["toppingsRougeBowls"][item.name]
                            )
                          }
                        />
                      ))
                    : ""}
                </div>
              </div>
              <div className={styles.whole_ing_card}>
                <h2>Extra Garnitures (4 max)</h2>
                <div className={styles.cards}>
                  {extraToppingsRougeBowls
                    ? extraToppingsRougeBowls.map((item) => (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"extraToppingsRougeBowls"}
                          handleShowSides={() => {
                            setShowSides(true);
                          }}
                          onClick={recalculate}
                          selected={
                            !!(
                              selected &&
                              selected["extraToppingsRougeBowls"] &&
                              selected["extraToppingsRougeBowls"][
                                item.name
                              ]
                            )
                          }
                        />
                      ))
                    : ""}
                </div>
              </div>
            </>
          ) : null}

          {dressingClicked && whichBowl === "Rouge Bowl" ? (
            <>
              <div className={styles.whole_ing_card}>
                <h2>Garnitures Incluses (choisissez 7)</h2>
                <div className={styles.cards}>
                  {toppingsRougeBowls
                    ? toppingsRougeBowls.map((item) => (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"toppingsRougeBowls"}
                          handleShowSides={() => {
                            setShowSides(true);
                          }}
                          onClick={recalculate}
                          selected={
                            !!(
                              selected &&
                              selected["toppingsRougeBowls"] &&
                              selected["toppingsRougeBowls"][item.name]
                            )
                          }
                        />
                      ))
                    : ""}
                </div>
              </div>
              <div className={styles.whole_ing_card}>
                <h2>Extra Garnitures (max 4)</h2>
                <div className={styles.cards}>
                  {extraToppingsRougeBowls
                    ? extraToppingsRougeBowls.map((item) => (
                        <Ingredient
                          key={item.name}
                          item={item}
                          type={"extraToppingsRougeBowls"}
                          handleShowSides={() => {
                            setShowSides(true);
                          }}
                          onClick={recalculate}
                          selected={
                            !!(
                              selected &&
                              selected["extraToppingsRougeBowls"] &&
                              selected["extraToppingsRougeBowls"][
                                item.name
                              ]
                            )
                          }
                        />
                      ))
                    : ""}
                </div>
              </div>
            </>
          ) : null}

          {/* {showSides && whichBowl !== "Poutine Poulet Rouge" ? (
            <div className={styles.whole_ing_card}>
              <h2>À côtés</h2>
              <div className={styles.cards}>
                {sides
                  ? sides.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"sides"}
                        onClick={recalculate}
                        selected={
                          !!(
                            selected &&
                            selected["sides"] &&
                            selected["sides"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null} */}

          {showSides || poutinDressingClicked ? (
            <div className={styles.whole_ing_card}>
              <h2>Dessert</h2>
              <div className={styles.cards}>
                {dessert
                  ? dessert.map((item) => (
                      <Ingredient
                        key={item.name}
                        item={item}
                        type={"dessert"}
                        onClick={recalculate}
                        selected={
                          !!(
                            selected &&
                            selected["dessert"] &&
                            selected["dessert"][item.name]
                          )
                        }
                      />
                    ))
                  : ""}
              </div>
            </div>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default Ingredients;
