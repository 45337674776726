// ----------- Bowls ----------//
export const bowls = [
  {
    name: "Rouge Bowl",
    namefr: "Rouge Bol",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Rouge Bowl.png",
  },
  {
    name: "Rouge Poutine",
    namefr: "Rouge Poutine",
    calories: 986,
    carbs: 91.8,
    fat: 56.3,
    protein: 27.9,
    soya: true,
    sulphite: true,
    wheat: true,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Poutine Poulet Rouge.png",
  },
  {
    name: "Mini Bowl",
    namefr: "Rouge Mini",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Mini Bowl.png",
  },
];
export const typeOfProtein = [
  {
    name: "Chicken Fillet",
    namefr: "Filet de poulet",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
    image: "/assets/images/Chicken Fillet.png",
  },
  {
    name: "Plant-Based Fillet",
    namefr: "Filet Végétal",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
    image: "/assets/images/Plant-Based Fillet.png",
  },
];
export const numbOfFlavors = [
  {
    name: "1 Flavour",
    namefr: "1 Saveur",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
  },
  {
    name: "2 Flavours",
    namefr: "2 saveurs",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
  },
];

// --------- Protein --------- //
export const protein = [
  {
    name: "BBQ Fusion (Sweet)",
    namefr: "BBQ Fusion (Sucré)",
    calories: 157,
    carbs: 1.3,
    fat: 4.9,
    protein: 27.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet).png",
  },
  {
    name: "Hercules (Lemon)",
    namefr: "Hercules (Citron)",
    calories: 172,
    carbs: 0.4,
    fat: 6.9,
    protein: 27.2,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon).png",
  },
  {
    name: "Volcano (Spicy)",
    namefr: "Volcano (Épicé)",
    calories: 156,
    carbs: 0.8,
    fat: 5,
    protein: 27.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy).png",
  },
  {
    name: "Shisha (Garlic)",
    namefr: "Shisha (À l’ail)",
    calories: 170,
    carbs: 0.8,
    fat: 6.5,
    protein: 27,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic).png",
  },
];
export const plantBasedProtein = [
  {
    name: "BBQ Fusion (Sweet)",
    namefr: "BBQ Fusion (Sucré)",
    calories: 146,
    carbs: 11.1,
    fat: 3.7,
    protein: 17.1,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet) Vege.png",
  },
  {
    name: "Hercules (Lemon)",
    namefr: "Hercules (Citron)",
    calories: 161,
    carbs: 10.2,
    fat: 5.7,
    protein: 17.1,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon) Vege.png",
  },
  {
    name: "Volcano (Spicy)",
    namefr: "Volcano (Épicé)",
    calories: 145,
    carbs: 10.6,
    fat: 3.8,
    protein: 17,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy) Vege.png",
  },
  {
    name: "Shisha (Garlic)",
    namefr: "Shisha (À l’ail)",
    calories: 159,
    carbs: 10.6,
    fat: 5.4,
    protein: 17,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic) Vege.png",
  },
];
export const twoFlavorProtein = [
  {
    name: "BBQ Fusion (Sweet) ",
    namefr: "BBQ Fusion (Sucré) ",
    calories: 105,
    carbs: 0.9,
    fat: 3.2,
    protein: 18.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet) 2.png",
  },
  {
    name: "Hercules (Lemon) ",
    namefr: "Hercules (Citron) ",
    calories: 115,
    carbs: 0.3,
    fat: 4.6,
    protein: 18.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon) 2.png",
  },
  {
    name: "Volcano (Spicy) ",
    namefr: "Volcano (Épicé) ",
    calories: 104,
    carbs: 0.5,
    fat: 3.3,
    protein: 18,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy) 2.png",
  },
  {
    name: "Shisha (Garlic) ",
    namefr: "Shisha (À l’ail) ",
    calories: 113,
    carbs: 0.5,
    fat: 4.4,
    protein: 18,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic) 2.png",
  },
];
export const twoFlavorPlantBasedProtein = [
  {
    name: "BBQ Fusion (Sweet) ",
    namefr: "BBQ Fusion (Sucré) ",
    calories: 97,
    carbs: 7.4,
    fat: 2.5,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet) Vege2.png",
  },
  {
    name: "Hercules (Lemon) ",
    namefr: "Hercules (Citron) ",
    calories: 107,
    carbs: 6.8,
    fat: 3.8,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon) Vege2.png",
  },
  {
    name: "Volcano (Spicy) ",
    namefr: "Volcano (Épicé) ",
    calories: 97,
    carbs: 7.1,
    fat: 2.5,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy) Vege2.png",
  },
  {
    name: "Shisha (Garlic) ",
    namefr: "Shisha (À l’ail) ",
    calories: 106,
    carbs: 7,
    fat: 3.6,
    protein: 11.3,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic) Vege2.png",
  },
];

export const proteinMini = [
  {
    name: "BBQ Fusion (Sweet) ",
    namefr: "BBQ Fusion (Sucré) ",
    calories: 105,
    carbs: 0.9,
    fat: 3.2,
    protein: 18.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet) 2.png",
  },
  {
    name: "Hercules (Lemon) ",
    namefr: "Hercules (Citron) ",
    calories: 115,
    carbs: 0.3,
    fat: 4.6,
    protein: 18.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon) 2.png",
  },
  {
    name: "Volcano (Spicy) ",
    namefr: "Volcano (Épicé) ",
    calories: 104,
    carbs: 0.5,
    fat: 3.3,
    protein: 18,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy) 2.png",
  },
  {
    name: "Shisha (Garlic) ",
    namefr: "Shisha (À l’ail) ",
    calories: 113,
    carbs: 0.5,
    fat: 4.4,
    protein: 18,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic) 2.png",
  },
];

export const proteinPlantBasedMini = [
  {
    name: "BBQ Fusion (Sweet) ",
    namefr: "BBQ Fusion (Sucré) ",
    plant: "BBQ Fusion (Sweet) Vege2",
    calories: 97,
    carbs: 7.4,
    fat: 2.5,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/BBQ Fusion (Sweet) Vege2.png",
  },
  {
    name: "Hercules (Lemon) ",
    namefr: "Hercules (Citron) ",
    plant: "Hercules (Lemon) Vege2",
    calories: 107,
    carbs: 6.8,
    fat: 3.8,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hercule (Lemon) Vege2.png",
  },
  {
    name: "Volcano (Spicy) ",
    namefr: "Volcano (Épicé) ",
    plant: "Volcano (Spicy) Vege2",
    calories: 97,
    carbs: 7.1,
    fat: 2.5,
    protein: 11.4,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Volcano (Spicy) Vege2.png",
  },
  {
    name: "Shisha (Garlic) ",
    namefr: "Shisha (À l’ail) ",
    plant: "Shisha (Garlic) Vege2",
    calories: 106,
    carbs: 7,
    fat: 3.6,
    protein: 11.3,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Shisha (Garlic) Vege2.png",
  },
];

export const extraTwoFillets = [
  {
    name: "No Thanks",
    namefr: "Non merci",
    image: "/assets/images/No Thanks.png",
  },
  {
    name: "Extra 2 Fillets ",
    namefr: "Extra 2 Filets",
    plant: "Extra 2 Fillets Vege",
    image: "/assets/images/Extra 2 Fillets .png",
  },
  {
    name: "Extra 1 Fillet",
    namefr: "Extra 1 Filet",
    plant: "Extra 1 Fillets Vege",
    image: "/assets/images/Extra 1 Fillet.png",
  },
];

// ---------- Base ---------- //
export const base = [
  {
    name: "Brown Rice",
    namefr: "Riz brun",
    calories: 218 * 2,
    carbs: 48.8 * 2,
    fat: 4.8 * 2,
    protein: 0 * 2,
    soya: true,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Brown Rice.png",
  },
  {
    name: "Bulgur Wheat",
    namefr: "Blé concassé",
    calories: 122 * 2,
    carbs: 15.5 * 2,
    fat: 5.5 * 2,
    protein: 2.5 * 2,
    soya: true,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bulgur Wheat.png",
  },
  {
    name: "Lettuce",
    namefr: "Laitue",
    calories: 9 * 2,
    carbs: 1.5 * 2,
    fat: 0.1 * 2,
    protein: 0.6 * 2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Lettuce Base.png",
  },
  {
    name: "Quinoa",
    namefr: "Quinoa",
    calories: 205 * 2,
    carbs: 32.2 * 2,
    fat: 5.5 * 2,
    protein: 6.7 * 2,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Quinoa.png",
  },
];

export const baseMini = [
  {
    name: "Brown Rice",
    namefr: "Riz brun",
    calories: 218,
    carbs: 48.8,
    fat: 4.8,
    protein: 0,
    soya: true,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Brown Rice.png",
  },
  {
    name: "Bulgur Wheat",
    namefr: "Blé concassé",
    calories: 122,
    carbs: 15.5,
    fat: 5.5,
    protein: 2.5,
    soya: true,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bulgur Wheat.png",
  },
  {
    name: "Lettuce",
    namefr: "Laitue",
    calories: 9,
    carbs: 1.5,
    fat: 0.1,
    protein: 0.6,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Lettuce Base.png",
  },
  {
    name: "Quinoa",
    namefr: "Quinoa",
    calories: 205,
    carbs: 32.2,
    fat: 5.5,
    protein: 6.7,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Quinoa.png",
  },
];

// ----------- Dressings ---------- //
export const rougeBowlsDressings = [
  {
    name: "Spicy Mayo",
    namefr: "Mayo épicée",
    calories: 192,
    carbs: 3,
    fat: 20,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Spicy Mayo.png",
  },
  {
    name: "Ceasar Ranch",
    namefr: "César Ranch",
    calories: 120,
    carbs: 2,
    fat: 12.2,
    protein: 0.5,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Ceasar Ranch.png",
  },
  {
    name: "Cilantro Lime",
    namefr: "Lime coriandre",
    calories: 119,
    carbs: 1,
    fat: 12.7,
    protein: 0.1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Cilantro Lime.png",
  },
  {
    name: "Hot Chili",
    namefr: "Chili Chaud",
    calories: 72,
    carbs: 3.1,
    fat: 6.6,
    protein: 0.2,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Hot Chili.png",
  },
  {
    name: "Honey Dijon",
    namefr: "Dijon Miel",
    calories: 153,
    carbs: 6.1,
    fat: 14.2,
    protein: 0.2,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Honey Dijon.png",
  },
  {
    name: "Sweet Zaatar",
    namefr: "Zaatar Doux",
    calories: 150,
    carbs: 3.1,
    fat: 15.3,
    protein: 0.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: true,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: true,
    all: true,
    image: "/assets/images/Sweet Zaatar.png",
  },
  {
    name: "No Thanks",
    namefr: "Non merci",
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
    image: "/assets/images/No Thanks.png",
  },
];

export const poutinDressingsAndToppings = [
  {
    name: "Spicy Mayo",
    namefr: "Mayo épicée",
    calories: 192,
    carbs: 3,
    fat: 20,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Spicy Mayo.png",
  },
  {
    name: "Tzatziki",
    namefr: "Tzatziki",
    calories: 60,
    carbs: 0,
    fat: 6,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tzatziki.png",
  },
  {
    name: "Red onion",
    namefr: "Oignon rouge",
    calories: 14,
    carbs: 3.1,
    fat: 0,
    protein: 0.4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Red onion.png",
  },
  {
    name: "Cucumber",
    namefr: "Concombre",
    calories: 5,
    carbs: 1.1,
    fat: 0,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Cucumber.png",
  },
  {
    name: "Tomatoes",
    namefr: "Tomate",
    calories: 7,
    carbs: 1.2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tomato.png",
  },
  {
    name: "Corn",
    namefr: "Maïs",
    calories: 31,
    carbs: 6,
    fat: 0.3,
    protein: 1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Corn.png",
  },
  {
    name: "Dried Cranberries",
    namefr: "Canneberges séchées",
    calories: 70,
    carbs: 17,
    fat: 0.2,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Dried Cranberries.png",
  },
  {
    name: "Peppers",
    namefr: "Poivron",
    calories: 10,
    carbs: 2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bell pepper.png",
  },
  {
    name: "Sweet Pickles",
    namefr: "Cornichons sucrés",
    calories: 34,
    carbs: 8,
    fat: 0.1,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Sweet pickle.png",
  },
  {
    name: "Kalamata Olives",
    namefr: "Olives Kalamata",
    calories: 36,
    carbs: 1.3,
    fat: 3.3,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Kalamata Olive.png",
  },
  {
    name: "Fried Onions",
    namefr: "Oignon Croustillant",
    calories: 87,
    carbs: 6,
    fat: 6.6,
    protein: 0.9,
    soya: false,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Crispy Onions.png",
  },
  {
    name: "Black Beans",
    namefr: "Haricots noirs",
    calories: 44,
    carbs: 7.6,
    fat: 0.2,
    protein: 3,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Black Beans.png",
  },
  {
    name: "Beets",
    namefr: "Betterave",
    calories: 13,
    carbs: 3,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Beets.png",
  },
  {
    name: "Jalapenos",
    namefr: "Jalapeno",
    calories: 3,
    carbs: 0.5,
    fat: 0.1,
    protein: 0.1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Jalapeno.png",
  },
  {
    name: "Feta Cheese",
    namefr: "Fromage feta",
    calories: 40,
    carbs: 0.5,
    fat: 3,
    protein: 3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Feta Cheese.png",
  },
  {
    name: "Monterey Jack cheese",
    namefr: "Monterey Jack",
    calories: 96,
    carbs: 2,
    fat: 8,
    protein: 4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Monterey Jack cheese.png",
  },
  {
    name: "Pineapple",
    namefr: "Ananas",
    calories: 18,
    carbs: 4.8,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Pineapple.png",
  },
];

// ----------- Toppings ---------- //
export const toppingsRougeBowls = [
  {
    name: "Romaine Lettuce",
    namefr: "Laitue romaine",
    calories: 4,
    carbs: 1,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Romaine Lettuce.png",
  },
  {
    name: "Black Beans",
    namefr: "Haricots noirs",
    calories: 44,
    carbs: 7.6,
    fat: 0.2,
    protein: 3,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Black Beans.png",
  },
  {
    name: "Beets",
    namefr: "Betterave",
    calories: 13,
    carbs: 3,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Beets.png",
  },
  {
    name: "Red onion",
    namefr: "Oignon rouge",
    calories: 14,
    carbs: 3.1,
    fat: 0,
    protein: 0.4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Red onion.png",
  },
  {
    name: "Cucumber",
    namefr: "Concombre",
    calories: 5,
    carbs: 1.1,
    fat: 0,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Cucumber.png",
  },
  {
    name: "Tomatoes",
    namefr: "Tomate",
    calories: 7,
    carbs: 1.2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tomato.png",
  },
  {
    name: "Corn",
    namefr: "Maïs",
    calories: 31,
    carbs: 6,
    fat: 0.3,
    protein: 1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Corn.png",
  },
  {
    name: "Dried Cranberries",
    namefr: "Canneberges séchées",
    calories: 70,
    carbs: 17,
    fat: 0.2,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Dried Cranberries.png",
  },
  {
    name: "Peppers",
    namefr: "Poivron",
    calories: 10,
    carbs: 2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bell pepper.png",
  },
  {
    name: "Sweet Pickles",
    namefr: "Cornichons sucrés",
    calories: 34,
    carbs: 8,
    fat: 0.1,
    protein: 0.2,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Sweet pickle.png",
  },
  {
    name: "Kalamata Olives",
    namefr: "Olives Kalamata",
    calories: 36,
    carbs: 1.3,
    fat: 3.3,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Kalamata Olive.png",
  },
  {
    name: "Fried Onions",
    namefr: "Oignon Croustillant",
    calories: 87,
    carbs: 6,
    fat: 6.6,
    protein: 0.9,
    soya: false,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Crispy Onions.png",
  },
  {
    name: "Jalapenos",
    namefr: "Jalapeno",
    calories: 3,
    carbs: 0.5,
    fat: 0.1,
    protein: 0.1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Jalapeno.png",
  },
  {
    name: "Tzatziki",
    namefr: "Tzatziki",
    calories: 60,
    carbs: 0,
    fat: 6,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tzatziki.png",
  },
  {
    name: "Feta Cheese",
    namefr: "Fromage feta",
    calories: 40,
    carbs: 0.5,
    fat: 3,
    protein: 3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Feta Cheese.png",
  },
  {
    name: "Monterey Jack cheese",
    namefr: "Monterey Jack",
    calories: 96,
    carbs: 2,
    fat: 8,
    protein: 4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Monterey Jack cheese.png",
  },
  {
    name: "Pineapple",
    namefr: "Ananas",
    calories: 18,
    carbs: 4.8,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Pineapple.png",
  },
];

export const extraToppingsRougeBowls = [
  {
    name: "Romaine Lettuce",
    namefr: "Laitue romaine",
    calories: 4,
    carbs: 1,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Romaine Lettuce.png",
  },
  {
    name: "Black Beans",
    namefr: "Haricots noirs",
    calories: 44,
    carbs: 7.6,
    fat: 0.2,
    protein: 3,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Black Beans.png",
  },
  {
    name: "Beets",
    namefr: "Betterave",
    calories: 13,
    carbs: 3,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Beets.png",
  },
  {
    name: "Red onion",
    namefr: "Oignon rouge",
    calories: 14,
    carbs: 3.1,
    fat: 0,
    protein: 0.4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Red onion.png",
  },
  {
    name: "Cucumber",
    namefr: "Concombre",
    calories: 5,
    carbs: 1.1,
    fat: 0,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Cucumber.png",
  },
  {
    name: "Tomatoes",
    namefr: "Tomate",
    calories: 7,
    carbs: 1.2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tomato.png",
  },
  {
    name: "Corn",
    namefr: "Maïs",
    calories: 31,
    carbs: 6,
    fat: 0.3,
    protein: 1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Corn.png",
  },
  {
    name: "Dried Cranberries",
    namefr: "Canneberges séchées",
    calories: 70,
    carbs: 17,
    fat: 0.2,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Dried Cranberries.png",
  },
  {
    name: "Peppers",
    namefr: "Poivron",
    calories: 10,
    carbs: 2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bell pepper.png",
  },
  {
    name: "Sweet Pickles",
    namefr: "Cornichons sucrés",
    calories: 34,
    carbs: 8,
    fat: 0.1,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Sweet pickle.png",
  },
  {
    name: "Kalamata Olives",
    namefr: "Olives Kalamata",
    calories: 36,
    carbs: 1.3,
    fat: 3.3,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Kalamata Olive.png",
  },
  {
    name: "Fried Onions",
    namefr: "Oignon Croustillant",
    calories: 87,
    carbs: 6,
    fat: 6.6,
    protein: 0.9,
    soya: false,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Crispy Onions.png",
  },
  {
    name: "Jalapenos",
    namefr: "Jalapeno",
    calories: 3,
    carbs: 0.5,
    fat: 0.1,
    protein: 0.1,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Jalapeno.png",
  },
  {
    name: "Feta Cheese",
    namefr: "Fromage feta",
    calories: 40,
    carbs: 0.5,
    fat: 3,
    protein: 3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Feta Cheese.png",
  },
  {
    name: "Monterey Jack cheese",
    namefr: "Monterey Jack",
    calories: 96,
    carbs: 2,
    fat: 8,
    protein: 4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Monterey Jack cheese.png",
  },
  {
    name: "Tzatziki",
    namefr: "Tzatziki",
    calories: 60,
    carbs: 0,
    fat: 6,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tzatziki.png",
  },
  {
    name: "Avocado Puree",
    namefr: "Purée d'avocat",
    calories: 79.5,
    carbs: 4.5,
    fat: 6.8,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Avocado Puree.png",
  },
  {
    name: "Pineapple",
    namefr: "Ananas",
    calories: 18,
    carbs: 4.8,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Pineapple.png",
  },
];

export const toppingsMiniBowl = [
  {
    name: "Romaine Lettuce",
    namefr: "Laitue romaine",
    calories: 4,
    carbs: 1,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Romaine Lettuce.png",
  },
  {
    name: "Black Beans",
    namefr: "Haricots noirs",
    calories: 44,
    carbs: 7.6,
    fat: 0.2,
    protein: 3,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Black Beans.png",
  },
  {
    name: "Beets",
    namefr: "Betterave",
    calories: 13,
    carbs: 3,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Beets.png",
  },
  {
    name: "Red onion",
    namefr: "Oignon rouge",
    calories: 14,
    carbs: 3.1,
    fat: 0,
    protein: 0.4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Red onion.png",
  },
  {
    name: "Cucumber",
    namefr: "Concombre",
    calories: 5,
    carbs: 1.1,
    fat: 0,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Cucumber.png",
  },
  {
    name: "Tomatoes",
    namefr: "Tomate",
    calories: 7,
    carbs: 1.2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tomato.png",
  },
  {
    name: "Corn",
    namefr: "Maïs",
    calories: 31,
    carbs: 6,
    fat: 0.3,
    protein: 1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Corn.png",
  },
  {
    name: "Dried Cranberries",
    namefr: "Canneberges séchées",
    calories: 70,
    carbs: 17,
    fat: 0.2,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Dried Cranberries.png",
  },
  {
    name: "Peppers",
    namefr: "Poivron",
    calories: 10,
    carbs: 2,
    fat: 0.1,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bell pepper.png",
  },
  {
    name: "Sweet Pickles",
    namefr: "Cornichons sucrés",
    calories: 34,
    carbs: 8,
    fat: 0.1,
    protein: 0.2,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Sweet pickle.png",
  },
  {
    name: "Kalamata Olives",
    namefr: "Olives Kalamata",
    calories: 36,
    carbs: 1.3,
    fat: 3.3,
    protein: 0.3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Kalamata Olive.png",
  },
  {
    name: "Fried Onions",
    namefr: "Oignon Croustillant",
    calories: 87,
    carbs: 6,
    fat: 6.6,
    protein: 0.9,
    soya: false,
    sulphite: false,
    wheat: true,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Crispy Onions.png",
  },
  {
    name: "Jalapenos",
    namefr: "Jalapeno",
    calories: 3,
    carbs: 0.5,
    fat: 0.1,
    protein: 0.1,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Jalapeno.png",
  },
  {
    name: "Tzatziki",
    namefr: "Tzatziki",
    calories: 60,
    carbs: 0,
    fat: 6,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Tzatziki.png",
  },
  {
    name: "Feta Cheese",
    namefr: "Fromage feta",
    calories: 40,
    carbs: 0.5,
    fat: 3,
    protein: 3,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Feta Cheese.png",
  },
  {
    name: "Monterey Jack cheese",
    namefr: "Monterey Jack",
    calories: 96,
    carbs: 2,
    fat: 8,
    protein: 4,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Monterey Jack cheese.png",
  },
  {
    name: "Avocado Puree",
    namefr: "Purée d'avocat",
    calories: 60,
    carbs: 4,
    fat: 5,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Avocado Puree.png",
  },
  {
    name: "Pineapple",
    namefr: "Ananas",
    calories: 18,
    carbs: 4.8,
    fat: 0,
    protein: 0,
    soya: false,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Pineapple.png",
  },
];

// ----------- Sides ---------- //
export const sides = [
  {
    name: "French Fries",
    namefr: "Frites Maison",
    calories: 498,
    carbs: 62.1,
    fat: 24.8,
    protein: 6.6,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/French Fries.png",
  },
  {
    name: "Small Poutine",
    namefr: "Petite Poutine",
    calories: 674,
    carbs: 69.2,
    fat: 37,
    protein: 16.1,
    soya: true,
    sulphite: true,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: true,
    sesame: false,
    all: true,
    image: "/assets/images/Small Poutine.png",
  },
  {
    name: "Brown Rice",
    namefr: "Riz Brun",
    calories: 218,
    carbs: 48.8,
    fat: 4.8,
    protein: 0,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Brown Rice.png",
  },
  {
    name: "Bulgur Wheat",
    namefr: "Blé Concassé",
    carbs: 32.2,
    fat: 5.5,
    protein: 6.7,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Bulgur Wheat.png",
  },
  {
    name: "Quinoa",
    namefr: "Quinoa",
    calories: 121.2,
    carbs: 16.2,
    fat: 4.8,
    protein: 3.3,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: false,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Quinoa.png",
  },
];

export const dessert = [
  {
    name: "Choco Mousse",
    namefr: "Choco Mousse",
    calories: 210,
    carbs: 23,
    fat: 14,
    protein: 1,
    soya: true,
    sulphite: false,
    wheat: false,
    milk: true,
    mustard: false,
    buckwheat: false,
    fish: false,
    eggs: false,
    sesame: false,
    all: true,
    image: "/assets/images/Choco mousse.png",
  }
];
