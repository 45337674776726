import styles from "../css/Home.module.css";
import Ingredients from "../components/Ingredients/Ingredients";
import Calculator from "./Calculator/Calculator";
import { CalculatorContextProvider } from "../context/CalculatorContext";
import React from "react";

const Main = () => {
  return (
    <div>
      <CalculatorContextProvider>
        <div>
          <div className={styles.container}>
            <Ingredients />
            <div className={styles.styicky}>
              <Calculator />
            </div>
          </div>
        </div>
      </CalculatorContextProvider>
    </div>
  );
};

export default Main;
