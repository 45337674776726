import { CalculatorContext } from "../../context/CalculatorContext";
import styles from "../../css/BottomNavCalculatorDisplay.module.css";
import React, { useContext } from "react";
import { BsChevronUp } from "react-icons/bs";
import BottomSheetCalculatorPopUp from "./BottomSheetCalculatorPopUp";

const BottomNacCalculatorDisplay = ({ prot, fat, carb, cal, alergies }) => {
  const { handleCalculatorSlider } = useContext(CalculatorContext);

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  function checkNumber(check, number) {
    if (check) {
      return number.toFixed(1);
    } else {
      return number.toFixed(0);
    }
  }

  const handleValue = (value) => {
    let total = prot + carb + fat;
    if (value === 0) {
      return 0;
    } else {
      return (value / total) * 100;
    }
  };

  return (
    <>
      <div className={styles.navContainer}>
        <nav className={styles.calcNavDisplay}>
          <div className={styles.bottomNavcontainer}>
            <div className={styles.values}>
              <p>{checkNumber(isFloat(cal), cal)}</p>
              <p>Calories</p>
            </div>
            <div className={styles.values}>
              <p>{checkNumber(isFloat(prot), prot)}g</p>
              <p>Protein</p>
            </div>
            <div className={styles.values}>
              <p>{checkNumber(isFloat(fat), fat)}g</p>
              <p>Lipides</p>
            </div>
            <div className={styles.values}>
              <p>{checkNumber(isFloat(carb), carb)}g</p>
              <p>Glucides</p>
            </div>
          </div>
        </nav>
      </div>
      <BottomSheetCalculatorPopUp
        prot={prot}
        fat={fat}
        carb={carb}
        cal={cal}
        alergies={alergies}
      />
      <div className={styles.arrowOpenCalc} onClick={handleCalculatorSlider}>
        <BsChevronUp color="#fff" size={30} style={{ margin: "auto" }} />
      </div>
    </>
  );
};

export default BottomNacCalculatorDisplay;
