import React, { useState, createContext } from "react";
import {
  bowls,
  typeOfProtein,
  numbOfFlavors,
  protein,
  plantBasedProtein,
  twoFlavorProtein,
  twoFlavorPlantBasedProtein,
  proteinMini,
  proteinPlantBasedMini,
  extraTwoFillets,
  base,
  baseMini,
  rougeBowlsDressings,
  poutinDressingsAndToppings,
  toppingsRougeBowls,
  extraToppingsRougeBowls,
  toppingsMiniBowl,
  sides,
  dessert,
} from "../data";

export const CalculatorContext = createContext();
export const CalculatorContextProvider = (props) => {
  const [selected, setSelected] = useState({});

  const [totalCals, setTotalCals] = useState(0);
  const [totalCarbs, setTotalCarbs] = useState(0);
  const [totalFats, setTotalFats] = useState(0);
  const [totalProtein, setTotalProtein] = useState(0);

  const [prot, setProt] = useState(0);
  const [fat, setFat] = useState(0);
  const [carb, setCarb] = useState(0);
  const [cal, setCal] = useState(0);

  const [path, setPath] = useState(null);

  const [calculatorSlider, setCalculatorSlider] = useState(false);

  const handleCalculatorSlider = () => {
    setCalculatorSlider(!calculatorSlider);
  };

  const updateCalcOnFoodType = (foodType) => {
    if (foodType) {
      setTotalCals(0);
      setTotalCarbs(0);
      setTotalFats(0);
      setTotalProtein(0);
      setProt(0);
    }
  };

  const startOver = () => {
    setSelected({});
  };

  // TODO:
  // Create Methods for switch case to reuse the code.

  const getOneItemOnly = (selected, name, items) => {
    const selItem = {};
    if (!selected || !selected[name]) {
      selItem[name] = items.filter((obj) => obj.name === name)[0];
    }
    return selItem;
  };

  const recalculate = (type, name) => {
    switch (type) {
      case "bowls":
        selected["bowls"] = getOneItemOnly(selected["bowls"], name, bowls);
        delete selected["typeOfProtein"];
        delete selected["numbOfFlavors"];
        delete selected["protein"];
        delete selected["plantBasedProtein"];
        delete selected["twoFlavorProtein"];
        delete selected["twoFlavorPlantBasedProtein"];
        delete selected["extraTwoFillets"];
        delete selected["base"];
        delete selected["rougeBowlsDressings"];
        delete selected["proteinMini"];
        delete selected["proteinPlantBasedMini"];
        delete selected["twoFlavorProteinPlantBasedMini"];
        delete selected["poutinDressingsAndToppings"];
        delete selected["baseMini"];
        delete selected["toppingsRougeBowls"];
        delete selected["extraToppingsRougeBowls"];
        delete selected["toppingsSaladBowl"];
        delete selected["extraToppingsSaladBowl"];
        delete selected["extraToppingsQuinoiBowl"];
        delete selected["toppingsMiniBowl"];
        delete selected["toppings"];
        delete selected["sides"];
        delete selected["dessert"];
        break;
      case "typeOfProtein":
        selected["typeOfProtein"] = getOneItemOnly(
          selected["typeOfProtein"],
          name,
          typeOfProtein
        );
        delete selected["numbOfFlavors"];
        delete selected["protein"];
        delete selected["plantBasedProtein"];
        delete selected["twoFlavorProtein"];
        delete selected["twoFlavorPlantBasedProtein"];
        delete selected["extraTwoFillets"];
        delete selected["base"];
        delete selected["rougeBowlsDressings"];
        delete selected["proteinMini"];
        delete selected["proteinPlantBasedMini"];
        delete selected["twoFlavorProteinPlantBasedMini"];
        delete selected["poutinDressingsAndToppings"];
        delete selected["baseMini"];
        delete selected["toppingsRougeBowls"];
        delete selected["extraToppingsRougeBowls"];
        delete selected["toppingsSaladBowl"];
        delete selected["extraToppingsSaladBowl"];
        delete selected["extraToppingsQuinoiBowl"];
        delete selected["toppingsMiniBowl"];
        delete selected["toppings"];
        delete selected["sides"];
        delete selected["dessert"];
        break;
      case "numbOfFlavors":
        selected["numbOfFlavors"] = getOneItemOnly(
          selected["numbOfFlavors"],
          name,
          numbOfFlavors
        );
        delete selected["protein"];
        delete selected["plantBasedProtein"];
        delete selected["twoFlavorProtein"];
        delete selected["twoFlavorPlantBasedProtein"];
        delete selected["extraTwoFillets"];
        delete selected["base"];
        delete selected["rougeBowlsDressings"];
        delete selected["proteinMini"];
        delete selected["proteinPlantBasedMini"];
        delete selected["twoFlavorProteinPlantBasedMini"];
        delete selected["poutinDressingsAndToppings"];
        delete selected["baseMini"];
        delete selected["toppingsRougeBowls"];
        delete selected["extraToppingsRougeBowls"];
        delete selected["toppingsSaladBowl"];
        delete selected["extraToppingsSaladBowl"];
        delete selected["extraToppingsQuinoiBowl"];
        delete selected["toppingsMiniBowl"];
        delete selected["toppings"];
        delete selected["sides"];
        delete selected["dessert"];
        break;
      case "protein":
        selected["protein"] = getOneItemOnly(
          selected["protein"],
          name,
          protein
        );
        break;
      case "plantBasedProtein":
        selected["plantBasedProtein"] = getOneItemOnly(
          selected["plantBasedProtein"],
          name,
          plantBasedProtein
        );
        break;
      case "twoFlavorProtein":
        if (selected["bowls"]) {
          const selTwoFlavorProtein = selected["twoFlavorProtein"]
            ? { ...selected["twoFlavorProtein"] }
            : {};
          if (selTwoFlavorProtein[name]) {
            delete selTwoFlavorProtein[name];
          } else {
            selTwoFlavorProtein[name] = twoFlavorProtein.filter(
              (obj) => obj.name === name
            )[0];
          }
          if (Object.keys(selTwoFlavorProtein).length > 2) {
            break;
          }
          selected["twoFlavorProtein"] = selTwoFlavorProtein;
        } else {
          const selProt = {};
          if (
            !selected["twoFlavorProtein"] ||
            !selected["twoFlavorProtein"][name]
          ) {
            selProt[name] = twoFlavorProtein.filter(
              (obj) => obj.name === name
            )[0];
          }
          selected["twoFlavorProtein"] = selProt;
        }
        break;

      case "twoFlavorPlantBasedProtein":
        if (selected["bowls"]) {
          const selTwoFlavorProtein = selected["twoFlavorPlantBasedProtein"]
            ? { ...selected["twoFlavorPlantBasedProtein"] }
            : {};
          if (selTwoFlavorProtein[name]) {
            delete selTwoFlavorProtein[name];
          } else {
            selTwoFlavorProtein[name] = twoFlavorPlantBasedProtein.filter(
              (obj) => obj.name === name
            )[0];
          }
          if (Object.keys(selTwoFlavorProtein).length > 2) {
            break;
          }
          selected["twoFlavorPlantBasedProtein"] = selTwoFlavorProtein;
        } else {
          const selProt = {};
          if (
            !selected["twoFlavorPlantBasedProtein"] ||
            !selected["twoFlavorPlantBasedProtein"][name]
          ) {
            selProt[name] = twoFlavorPlantBasedProtein.filter(
              (obj) => obj.name === name
            )[0];
          }
          selected["twoFlavorPlantBasedProtein"] = selProt;
        }
        break;

      case "proteinMini":
        selected["proteinMini"] = getOneItemOnly(
          selected["proteinMini"],
          name,
          proteinMini
        );
        break;
      case "proteinPlantBasedMini":
        selected["proteinPlantBasedMini"] = getOneItemOnly(
          selected["proteinPlantBasedMini"],
          name,
          proteinPlantBasedMini
        );
        break;
      // -------
      case "poutinDressingsAndToppings":
        if (selected["bowls"] && selected["bowls"]["Rouge Poutine"]) {
          const selPoutinDressingsAndToppings = selected[
            "poutinDressingsAndToppings"
          ]
            ? { ...selected["poutinDressingsAndToppings"] }
            : {};
          if (selPoutinDressingsAndToppings[name]) {
            delete selPoutinDressingsAndToppings[name];
          } else {
            selPoutinDressingsAndToppings[name] =
              poutinDressingsAndToppings.filter((obj) => obj.name === name)[0];
          }
          if (Object.keys(selPoutinDressingsAndToppings).length > 3) {
            break;
          }
          selected["poutinDressingsAndToppings"] =
            selPoutinDressingsAndToppings;
        }
        break;

      case "extraTwoFillets":
        const selExtraTwoFillets = {};
        if (
          !selected["extraTwoFillets"] ||
          !selected["extraTwoFillets"][name]
        ) {
          selExtraTwoFillets[name] = extraTwoFillets.filter(
            (obj) => obj.name === name
          )[0];
        }
        selected["extraTwoFillets"] = selExtraTwoFillets;
        break;

      // ------------------------
      case "base":
        // selected["base"] = getOneItemOnly(selected["base"], name, base);

        if (selected["bowls"]) {
          const selBase = selected["base"] ? { ...selected["base"] } : {};
          if (selBase[name]) {
            delete selBase[name];
          } else {
            selBase[name] = base.filter((obj) => obj.name === name)[0];
          }
          if (Object.keys(selBase).length > 2) {
            break;
          }
          selected["base"] = selBase;
        } else {
          const selProt = {};
          if (!selected["base"] || !selected["base"][name]) {
            selProt[name] = base.filter((obj) => obj.name === name)[0];
          }
          selected["base"] = selProt;
        }
        break;

      case "baseMini":
        selected["baseMini"] = getOneItemOnly(
          selected["baseMini"],
          name,
          baseMini
        );
        // if (selected["bowls"]) {
        //   const selBaseMini = selected["baseMini"]
        //     ? { ...selected["baseMini"] }
        //     : {};
        //   if (selBaseMini[name]) {
        //     delete selBaseMini[name];
        //   } else {
        //     selBaseMini[name] = baseMini.filter((obj) => obj.name === name)[0];
        //   }
        //   if (Object.keys(selBaseMini).length > 2) {
        //     break;
        //   }
        //   selected["baseMini"] = selBaseMini;
        // } else {
        //   const selProt = {};
        //   if (!selected["baseMini"] || !selected["baseMini"][name]) {
        //     selProt[name] = baseMini.filter((obj) => obj.name === name)[0];
        //   }
        //   selected["baseMini"] = selProt;
        // }
        break;
      // ------------------------

      case "rougeBowlsDressings":
        const selrougeBowlsDressings = {};
        if (
          !selected["rougeBowlsDressings"] ||
          !selected["rougeBowlsDressings"][name]
        ) {
          selrougeBowlsDressings[name] =
            rougeBowlsDressings.filter((obj) => obj.name === name)[0];
        }
        selected["rougeBowlsDressings"] =
          selrougeBowlsDressings;
        break;

      case "toppingsRougeBowls":
        if (
          (selected["bowls"] && selected["bowls"]["Rouge Bowl"]) ||
          selected["bowls"]["Rouge Bowl 2 Flavours"] ||
          selected["bowls"]["Mini Bowl"]
        ) {
          const selToppingsRougeVeggiebowls = selected[
            "toppingsRougeBowls"
          ]
            ? { ...selected["toppingsRougeBowls"] }
            : {};
          if (selToppingsRougeVeggiebowls[name]) {
            delete selToppingsRougeVeggiebowls[name];
          } else {
            selToppingsRougeVeggiebowls[name] = toppingsRougeBowls.filter(
              (obj) => obj.name === name
            )[0];
          }
          if (Object.keys(selToppingsRougeVeggiebowls).length > 7) {
            break;
          }
          selected["toppingsRougeBowls"] = selToppingsRougeVeggiebowls;
        }
        break;

      case "extraToppingsRougeBowls":
        if (
          (selected["bowls"] && selected["bowls"]["Rouge Bowl"]) ||
          selected["bowls"]["Rouge Bowl 2 Flavours"]
        ) {
          const selExtraToppingsRougeVeggiebowls = selected[
            "extraToppingsRougeBowls"
          ]
            ? { ...selected["extraToppingsRougeBowls"] }
            : {};
          if (selExtraToppingsRougeVeggiebowls[name]) {
            delete selExtraToppingsRougeVeggiebowls[name];
          } else {
            selExtraToppingsRougeVeggiebowls[name] =
              extraToppingsRougeBowls.filter(
                (obj) => obj.name === name
              )[0];
          }
          if (Object.keys(selExtraToppingsRougeVeggiebowls).length > 4) {
            break;
          }
          selected["extraToppingsRougeBowls"] =
            selExtraToppingsRougeVeggiebowls;
        } else if (selected["bowls"] && selected["bowls"]["Mini Bowl"]) {
          const selExtraToppingsRougeVeggiebowls = selected[
            "extraToppingsRougeBowls"
          ]
            ? { ...selected["extraToppingsRougeBowls"] }
            : {};
          if (selExtraToppingsRougeVeggiebowls[name]) {
            delete selExtraToppingsRougeVeggiebowls[name];
          } else {
            selExtraToppingsRougeVeggiebowls[name] =
              extraToppingsRougeBowls.filter(
                (obj) => obj.name === name
              )[0];
          }
          if (Object.keys(selExtraToppingsRougeVeggiebowls).length > 3) {
            break;
          }
          selected["extraToppingsRougeBowls"] =
            selExtraToppingsRougeVeggiebowls;
        }
        break;
      // ------------------

      case "toppingsMiniBowl":
        if (selected["bowls"] && selected["bowls"]["Mini Bowl"]) {
          const selToppingsMiniBowl = selected["toppingsMiniBowl"]
            ? { ...selected["toppingsMiniBowl"] }
            : {};
          if (selToppingsMiniBowl[name]) {
            delete selToppingsMiniBowl[name];
          } else {
            selToppingsMiniBowl[name] = toppingsMiniBowl.filter(
              (obj) => obj.name === name
            )[0];
          }
          if (Object.keys(selToppingsMiniBowl).length > 3) {
            break;
          }
          selected["toppingsMiniBowl"] = selToppingsMiniBowl;
        }
        break;
      case "sides":
        selected["sides"] = getOneItemOnly(selected["sides"], name, sides);
        break;
      case "dessert":
        selected["dessert"] = getOneItemOnly(
          selected["dessert"],
          name,
          dessert
        );
        break;
      // ------------------

      default:
        break;
    }

    setSelected({ ...selected });
  };

  const value = {
    totalCals,
    setTotalCals,
    totalCarbs,
    setTotalCarbs,
    totalFats,
    setTotalFats,
    totalProtein,
    setTotalProtein,
    prot,
    setProt,
    fat,
    setFat,
    carb,
    setCarb,
    cal,
    setCal,
    updateCalcOnFoodType,
    recalculate,
    selected,
    startOver,
    path,
    setPath,
    handleCalculatorSlider,
    calculatorSlider
  };

  return (
    <CalculatorContext.Provider value={value}>
      {props.children}
    </CalculatorContext.Provider>
  );
};
