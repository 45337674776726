import React from "react";
import styles from "../../css/Ingredients.module.css";

function Ingredient({
  item,
  button,
  type,
  whichBowl,
  onClick,
  selected,
  handleBowlClick,
  handleProteinTypeClick,
  handleNumOfFlavors,
  handleProteinClick,
  handleMiniProteinClick,
  handleBaseClicked,
  handleBaseMiniClicked,
  handleDressingClicked,
  setWhichBowl,
  setWhichProteinType,
  setWhichNumOfFalvors,
  handleShowSides,
  handleAllReset,
  handleResetfromProteinType,
  handlePoutineDressingClicked,
}) {
  const { name, namefr, calories, carbs, fat, protein, image } = item;

  const handleItemClick = () => {
    onClick(type, name);
    if (type === "bowls") {
      handleBowlClick();
      setWhichBowl(name);
      handleAllReset();
    } else if (type === "typeOfProtein") {
      setWhichProteinType(name);
      handleProteinTypeClick();
      handleResetfromProteinType();
    } else if (type === "numbOfFlavors") {
      setWhichNumOfFalvors(name);
      handleNumOfFlavors();
    } else if (
      type === "protein" ||
      type === "twoFlavorProtein" ||
      type === "plantBasedProtein" ||
      type === "twoFlavorPlantBasedProtein"
    ) {
      handleProteinClick();
    } else if (
      type === "proteinMini" ||
      type === "proteinPlantBasedMini" ||
      type === "twoFlavorProteinMini" ||
      type === "twoFlavorProteinPlantBasedMini"
    ) {
      handleMiniProteinClick();
    } else if (type === "base" || type === "proteinMini") {
      handleBaseClicked();
    } else if (type === "rougeBowlsDressings" || type === "proteinMini") {
      handleDressingClicked();
    } else if (type === "poutinDressingsAndToppings") {
      handlePoutineDressingClicked();
    } else if (type === "baseMini") {
      handleBaseMiniClicked();
    } else if (
      type === "toppingsRougeBowls" ||
      type === "extraToppingsRougeBowls" ||
      type === "toppingsMiniBowl"
    ) {
      handleShowSides();
    }
  };

  const getButton = () => {
    return (
      <div className={styles.card} onClick={handleItemClick}>
        <div className={styles.h2Group}>
          <h2 className={selected ? styles.rougeBtnActive : styles.rougeBtn}>
            {namefr}
          </h2>
        </div>
      </div>
    );
  };

  const getIng = () => {
    return (
      <div className={styles.card} onClick={handleItemClick}>
        <div className={styles.ingredientImgCont}>
          <img
            className={
              name === "Plant-Based Fillet"
                ? styles.scalePantbasedFillter
                : name === "Mini Bowl" || name === "Choco Boost"
                ? styles.scaleMiniBowl
                : null
            }
            src={image}
            alt={`${name}`}
          />
        </div>
        <div className={styles.foodTypeName}>
          <p>{`${namefr}`}</p>
          <p className={styles.subText}>{calories} Calories</p>
        </div>

        <div className={styles.circle}>
          <img
            className={styles.ellipse}
            src="/assets/images/Ellipse.png"
            alt=""
          />
        </div>
        {selected ? (
          <div className={styles.circle}>
            <img
              className={styles.rouster}
              src="/assets/images/rouster.png"
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {type !== "bowls" || name === "No Thanks" ? (
          <div
            className={styles.tooltiptext}
            style={
              name === "Extra 1 Fillet" ||
              name === "Extra 2 Fillets " ||
              name === "No Thanks" ||
              name === "Chicken Fillet" ||
              name === "Plant-Based Fillet"
                ? { display: "none" }
                : {}
            }
          >
            <p className={styles.tooltiptextP}>
            Calories : {whichBowl === "Mini Bowl" ? calories / 2 : calories}
            </p>
            <p className={styles.tooltiptextP}>
            Glucides : {whichBowl === "Mini Bowl" ? carbs / 2 : carbs} G
            </p>
            <p className={styles.tooltiptextP}>
            Lipides : {whichBowl === "Mini Bowl" ? fat / 2 : fat} G
            </p>
            <p className={styles.tooltiptextP}>
            Protéines : {whichBowl === "Mini Bowl" ? protein / 2 : protein} G
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return button ? getButton() : getIng();
}

export default Ingredient;
